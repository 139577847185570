
























// import { PostTicket, ValidatePlay } from '@/api/tickets';
import { GetResponseErrors } from '@/common/utils';
import { KeypressEvent } from '@/models/interfaces/events/Keyboard';
import Vue, { VueConstructor } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Keyboard from './components/Keyboard.vue';
import OperatorContainer from './components/OperatorContainer.vue';
import TheSnackbar from '@/components/TheSnackbar.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
import AsyncDialog from '@/components/AsyncDialog.vue';
import { Recharge } from '@/models/interfaces/Recharge';
import { postRecharge } from '@/api/recharges';
// import { GetLotteries } from '@/api/lotteries';


export default (Vue as VueConstructor<Vue & {
  $refs: any,
}>).extend({
  name: 'sales',
  metaInfo: {
    title: 'Nueva venta',
  },
  components: { Keyboard, TheSnackbar, OperatorContainer, ConfirmDialog, AsyncDialog },
  data: () => ({
    keyboardShow: false,
    isConfirmDialogActive: false,
    keyboardLoading: false,
    loadingPlays: false,
  }),
  activated() {
    // algo
  },
  computed: {
    ...mapGetters('sales', ['selectedOperator']),
    ...mapGetters('auth',['user']),
  },
  methods: {
    ...mapActions('auth',['reloadUser']),
    ...mapActions('sales',['setRecharge', 'clearSelectedOperator']),
    handleKeyboardClose() {
      this.keyboardShow = false
    },
    handleKeyboardShow() {
      this.keyboardShow = true
    },
    async handleKeypress(e: KeypressEvent) {
      if (e.key == '{hide}') {
        this.keyboardShow = false
      } else if (e.key == '{play}') {
        // this.addPlayItem()
      } else if (e.key == '{finish}') {
        this.validateRecharge()
      } else if (e.key == '{clear}') {
        // this.clearPlays()
        // this.$refs.lotteryDialog.clearLotteries()
      }
    },
    async validateRecharge() {
      if (this.selectedOperator == null) {
        this.$refs.keyboard.animateLotteryButton();
        this.showError('Debe seleccionar un operador');
        return;
      }
      if (this.$refs.keyboard.phoneIsValid()) {
        const recharge: Recharge = await this.$refs.keyboard.buildRecharge();
        if(isNaN(recharge.amount) || recharge.amount < 25) {
          (this.$refs.snackbar as any).show('Debe introducir un monto válido');
          this.$refs.keyboard.setFocusTo('amounttextbox')
          return;
        }

        this.setRecharge(recharge);

        this.isConfirmDialogActive = true;

        // recharge.lotteries = [...this.selectedLotteries];
      } else {
        this.showError('Debe introducir un número de teléfono válido');
      }
    },
    async finishTicket() {
      try {
        // await PostTicket(this.currentPlayItemToSend.slice(0))
        const rechargeToSend = await this.$refs.keyboard.buildRecharge();
        console.log('rechargeToSend', rechargeToSend);
        await postRecharge(rechargeToSend);
        await this.reloadUser();
        this.showError('Recarga enviada exitosamente.');
        this.$refs.keyboard.clearFields();
        this.clearSelectedOperator();
      } catch (err) {
        console.error(err);
        (this.$refs.snackbar as any).show(GetResponseErrors(err))
      }
    },
    showError(message: string) {
      (this.$refs.snackbar as any).show(message);
    },
    handleOperatorClick() {
      this.keyboardShow = true
    }
  },
})
