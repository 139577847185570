














import { Operator } from '@/models/interfaces/Operator';
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import OperatorItem from './OperatorItem.vue';
export default Vue.extend({
  components: { OperatorItem },
  name: 'operator-container',
  async created() {
    await this.loadOperators();
  },
  methods: {
    ...mapActions('sales', ['loadOperators', 'selectOperator']),
    handleOperatorClick(operator: Operator) {
      this.$emit('click', operator);
      this.selectOperator(operator);
    }
  },
  computed: mapGetters('sales', ['operators'])
})
